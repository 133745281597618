<template>
  <div class="share-container">
    <div class="share-order-pay">
      <van-count-down
        v-if="limitTime"
        class="limit-time"
        :auto-start="autoStart"
        ref="order_count_down"
        :time="lefttime"
        format="mm 分 ss 秒"
        @finish="timeFinish"
      />
      <img src="../assets/icon/share/time.png" />
      <p class="order-p">{{orderTip}}</p>
      <div
        v-if="this.limitTime"
        class="share-tip row-center"
      >
        <p>请您移步手机端“Meta彼岸”应用在“我的”-“待支付”订单中完成支付</p>
      </div>
      <div class="row-center">
        <button
          style="margin-right:1.125rem;"
          @click="goPath('/')"
        >返回首页</button>
        <button @click="goPath('/order')">查看订单</button>
      </div>
    </div>
    <div class="share-download-app row-center">
      <div class="download-app-tip">
        <img src="../assets/icon/share/tips.png" />
        <p>各大手机应用商店均可搜索“Meta彼岸”即可下载应用。</p>
      </div>
      <div class="download-app-logo">
        <img src="../assets/icon/share/logo.png" />
        <p>Meta彼岸</p>
      </div>
    </div>
  </div>
</template>
<script>
import api from '../api/index-client'
import Vue from 'vue'
import { CountDown } from 'vant';
Vue.use(CountDown);

export default {
  data () {
    return {
      limitTime: false,
      minutes: 5,
      seconds: 0,
      orderTip: '订单创建成功但未完成支付',
      orderInfo: {},
      lefttime: 300000,
      autoStart: false
    }
  },
  mounted () {
    this.$store.commit('SHOW_APPLOADING')
    this.getOrderDetailsInfo()
  },
  methods: {
    goPath (path) {
      localStorage.setItem('orderIndex','2')
      this.$router.push(path)
    },
    showtime () {
      var nowtime = new Date()  //获取当前时间
      var endtime = this.$route.query.type == 'buy' ? new Date(this.orderInfo.createTime.replace(/-/g,"/")) : new Date(this.orderInfo.sendTime.replace(/-/g,"/"));  //定义结束时间
      this.lefttime = (endtime.getTime() + 300000) - (nowtime.getTime()) //距离结束时间的毫秒数]
      this.limitTime = true
      this.autoStart = true
    },
    num (n) {
      return n < 10 ? '0' + n : '' + n
    },
    // 查询支付状态 
    getOrderDetailsInfo (e) {
      var url = this.$route.query.type == 'buy' ? 'order/detail/' + this.$route.query.unique : 'gift/detail/' + this.$route.query.unique
      if (this.$route.query.type == 'buy') {
        api
          .get(url)
          .then(result => {
            if (result.data.success) {
              this.orderInfo = result.data.data
              if (this.orderInfo.status == 0 && this.orderInfo.refundStatus == 0 && this.orderInfo.paid == 0) {
                this.showtime()
              } else if (this.orderInfo.status == 4 && this.orderInfo.refundStatus == 0 && this.orderInfo.paid == 0) {
                this.limitTime = false
                this.orderTip = '订单已取消'
                // this.$router.push('/orderDetail?unique=' + this.$route.query.unique)
              }

            } else {
              this.$toast({
                message: result.data.msg,
                icon: require('../assets/icon/toast-error.png'),
              });
            }
            this.$store.commit('HIDE_APPLOADING')
          })
      } else {
        api
          .post(url)
          .then(result => {
            if (result.data.success) {
              this.orderInfo = result.data.data
              if (this.orderInfo.status == 5) {
                this.showtime()
              } else if (this.orderInfo.status == 8) {
                this.limitTime = false
                this.orderTip = '订单已取消'
                // this.$router.push('/orderDetail?unique=' + this.$route.query.unique)
              }

            } else {
              this.$toast({
                message: result.data.msg,
                icon: require('../assets/icon/toast-error.png'),
              });
            }
            this.$store.commit('HIDE_APPLOADING')
          })
      }

    },
    timeFinish () {
      this.$store.commit('SHOW_APPLOADING')
      this.orderCancer()
    },

    orderCancer () {
      var url = this.$route.query.type == 'buy' ? 'order/detail/' + this.$route.query.unique : 'gift/detail/' + this.$route.query.unique
      if (this.$route.query.type == 'buy') {
        api
          .get(url)
          .then(result => {
            if (result.data.success) {
              this.$router.replace('/orderDetail?unique=' + this.$route.query.unique + '&type=buy')
            } else {
              this.$toast({
                message: result.data.msg,
                icon: require('../assets/icon/toast-error.png'),
              });
            }
          })
      } else {
        api
          .post(url)
          .then(result => {
            if (result.data.success) {
              this.$router.replace('/orderDetail?unique=' + this.$route.query.unique + '&type=donation')
            } else {
              this.$toast({
                message: result.data.msg,
                icon: require('../assets/icon/toast-error.png'),
              });
            }
          })
      }

    },
  },
}
</script>